import React from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Navigator from './components/Navigator';
import Content from './components/Content';

let theme = createMuiTheme({
                             typography: {
                               useNextVariants: true,
                               h5: {
                                 fontWeight: 500,
                                 fontSize: 26,
                                 letterSpacing: 0.5,
                               },
                             },
                             palette: {
                               primary: {
                                 light: '#63ccff',
                                 main: '#009be5',
                                 dark: '#006db3',
                               },
                             },
                             shape: {
                               borderRadius: 8,
                             },
                           });

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#18202c',
      },
    },
    MuiButton: {
      label: {
        textTransform: 'initial',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing.unit,
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'initial',
        margin: '0 16px',
        minWidth: 0,
        [theme.breakpoints.up('md')]: {
          minWidth: 0,
        },
      },
      labelContainer: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing.unit,
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiList: {
      root: {
        color: 'inherit',
      }
    },
    MuiListItem: {
      gutters: {
        color: 'inherit',
      }
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
        color: '#fff',
      },
      secondary: {
        color: 'rgba(255, 255, 255, 0.7)',
      }
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
    MuiInputBase: {
      root: {
        color: 'inherit',
      }
    }
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    ...theme.mixins,
    toolbar: {
      minHeight: 48,
    },
  },
};

const drawerWidth = 350;

const styles = {
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  mainContent: {
    flex: 1,
    background: '#eaeff1',
    width: '100%',
    height: '100%',
  },
};

class Paperbase extends React.Component {
  state = {
    mobileOpen: false,
    pdata: [],
    buffer: 40,
  };
  
  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };
  
  handleChildClick = e => {
    this.setState({
                    pdata: e
    });
  }
  
  handleBufferChange = e => {
    this.setState({
      buffer: e
                  })
  }
  
  render() {
    const { classes } = this.props;
    
    return (
      <MuiThemeProvider theme={theme}>
        <div className={classes.root}>
          <CssBaseline />
          <div className={classes.appContent}>
            <main className={classes.mainContent}>
              <Content _handleData={this.handleChildClick} buffer={this.state.buffer} />
            </main>
          </div>
          <nav className={classes.drawer}>
            <Hidden smUp implementation="js">
              <Navigator
                PaperProps={{ style: { width: drawerWidth } }}
                buffer={this.state.buffer}
                anchor="right"
                variant="temporary"
                open={this.state.mobileOpen}
                onClose={this.handleDrawerToggle}
                pdata={this.state.pdata}
              />
            </Hidden>
            <Hidden xsDown implementation="css">
              <Navigator PaperProps={{ style: { width: drawerWidth } }} buffer={this.state.buffer} pdata={this.state.pdata} _handleBuffer={this.handleBufferChange} />
            </Hidden>
          </nav>
        </div>
      </MuiThemeProvider>
    );
  }
}

Paperbase.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Paperbase);
