import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map, withLeaflet, FeatureGroup, GeoJSON } from "react-leaflet";
import WMTSTileLayer from "react-leaflet-wmts";
import { EditControl } from "react-leaflet-draw";
import L from "leaflet";
import reproject from "reproject";
import axios from "axios";
import rewind from "geojson-rewind";
import wkx from "wkx";
import wicket from "wicket";
import Terraformer from 'terraformer-wkt-parser';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

let jsts = require("jsts");

const styles = theme => ({
  paper: {
    margin: 'auto',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
  },
  map: {
    width: '100%',
    height: '100%',
  },
});

class Content extends Component {
  constructor (props) {
    super(props);
    
    this.state = {
      perimeterData: {
        id: 1,
        shape: null
      },
      webData: {
        webid: 2,
        webshape: null
      }
    };
  }
  
  _onEdited = e => {
    /* let numEdited = 0;
    e.layers.eachLayer(layer => {
      numEdited += 1;
    });
    //console.log(`_onEdited: edited ${numEdited} layers`, e); */
    
    this._onChange();
  };
  
  _onCreated = e => {
    let type = e.layerType;
    // let layer = e.layer;
    if (type === "marker") {
      //console.log("_onCreated: marker created", e);
    } else {
      //console.log("_onCreated: something else created:", type, e);
    }
    this._onChange();
  };
  
  _onDeleted = e => {
    /* let numDeleted = 0;
    e.layers.eachLayer(layer => {
      numDeleted += 1;
    });
    //console.log(`onDeleted: removed ${numDeleted} layers`, e); */
    
    this._onChange();
  };
  
  _clickFeature = e => {
    // let myBuffer = parseFloat(window.prompt('Grootte van de buffer?'));
    // let myBuffer = 20;
    let myBuffer = parseFloat(this.props.buffer);
    let layer = e.layer;
    if (layer === null) {
      window.alert("no layer");
      return null;
    }
    if (typeof layer._mRadius !== "undefined") {
      myBuffer = myBuffer + layer._mRadius;
    }
    let primitive = layer.toGeoJSON();
    // let bufferGeojson = buffer(primitive, myBuffer, {units: 'metres'});
    if (typeof primitive.features !== "undefined") {
      primitive = primitive.features[0];
    }
    let coord = null;
    if (typeof layer.getBounds !== "undefined") {
      coord = layer.getBounds().getSouthWest();
    } else {
      coord = layer.getLatLng();
    }
    let zone = this.getZone(coord.lat, coord.lng);
    let crss = {
      "proj": "+proj=utm +zone=" + zone + " +ellps=WGS84 +datum=WGS84 +units=m +no_defs",
      "unproj": "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs"
    };
    let reader = new jsts.io.GeoJSONReader();
    let writer = new jsts.io.GeoJSONWriter();
    let geom = reader.read(reproject.reproject(primitive, "unproj", "proj", crss));
    let buffer4326 = reproject.reproject(writer.write(geom.geometry.buffer(myBuffer)), "proj", "unproj", crss);// writer.write(geom.geometry.buffer(myBuffer));
    //console.log(buffer4326);
    
    this.setState({
                    perimeterData: {
                      id: Math.random(),
                      shape: buffer4326
                    }
                  });
    // Gebruik crss_lambert omdat we de coordinaten naar het juiste systeem willen brengen zodat we met de webservice kunnen praten
    let crss_lambert = {
      "proj": "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs",
      "unproj": "+proj=lcc +lat_1=51.16666723333333 +lat_2=49.8333339 +lat_0=90 +lon_0=4.367486666666666 +x_0=150000.013 +y_0=5400088.438 +ellps=intl +towgs84=-106.869,52.2978,-103.724,0.3366,-0.457,1.8422,-1.2747 +units=m +no_defs"
    };
    
    let projWktWithBuffer;
    if (myBuffer === 0) {
      projWktWithBuffer = Terraformer.convert(writer.write(reader.read(reproject.reproject(primitive, "proj", "unproj", crss_lambert)).geometry));
    } else {
      projWktWithBuffer = Terraformer.convert(writer.write(reader.read(reproject.reproject(primitive, "proj", "unproj", crss_lambert)).geometry.buffer(myBuffer)));
    }
    
    let me = this;
    // 'http://geoservices.informatievlaanderen.be/overdrachtdiensten/GRB/wfs?request=GetFeature&service=wfs&version=1.1.0&TYPENAME=ADP&outputFormat=application/json&srsName=EPSG:31370&CQL_FILTER=INTERSECTS(SHAPE,' + projWktWithBuffer + ')'
    let url = 'http://geoservices.informatievlaanderen.be/overdrachtdiensten/GRB/wfs?request=GetFeature&service=wfs&version=1.1.0&TYPENAME=ADP&outputFormat=application/json&srsName=EPSG:31370&CQL_FILTER=INTERSECTS(SHAPE,' + projWktWithBuffer + ')'
    axios.get(url)
          .then(function (response) {
      let wktArr = new wicket.Wkt();
      let first = true;
      response.data.features.forEach((perc) => {
        let wkt = Terraformer.convert(writer.write(reader.read(reproject.reproject(perc, "unproj", "proj", crss_lambert)).geometry)); // terug converten naar juiste coordsysteem
        if(first) {
          wktArr.read(wkt);
          first = false;
        } else {
          wktArr.merge(new wicket.Wkt(wkt));
        }
      })
      let wkt = wkx.Geometry.parse(wktArr.write());
      let geojson = rewind(wkt.toGeoJSON(), false);
      me.setState({
                    webData: {
                      webid: Math.random(),
                      webshape: geojson
                    }
                  });
      me.props._handleData(response.data.features);
    });
  };
  
  _onMounted = drawControl => {
    //console.log("_onMounted", drawControl);
  };
  
  _onEditStart = e => {
    //console.log("_onEditStart", e);
  };
  
  _onEditStop = e => {
    //console.log("_onEditStop", e);
  };
  
  _onDeleteStart = e => {
    //console.log("_onDeleteStart", e);
  };
  
  _onDeleteStop = e => {
    //console.log("_onDeleteStop", e);
  };
  
  getZone = (lat, lng) => {
    // Get the UTM zone
    let zoneNumber = Math.floor((lng + 180) / 6) + 1;
    
    if (lat >= 56.0 && lat < 64.0 && lng >= 3.0 && lng < 12.0) {
      zoneNumber = 32;
    }
    //Special zones for Svalbard
    if (lat >= 72.0 && lat < 84.0) {
      if (lng >= 0.0 && lng < 9.0) {
        zoneNumber = 31;
      }
      else if (lng >= 9.0 && lng < 21.0) {
        zoneNumber = 33;
      }
      else if (lng >= 21.0 && lng < 33.0) {
        zoneNumber = 35;
      }
      else if (lng >= 33.0 && lng < 42.0) {
        zoneNumber = 37;
      }
    }
    return zoneNumber;
  }
  
  render() {
    const { classes } = this.props;
    //[ 151.75184905529022, -32.936405408671995 ] 51.087106, 4.011464
    const center = L.latLng([51.087106, 4.011464]);
    const zoom = 18;
  
    const { perimeterData:{ id, shape } } = this.state
    const { webData:{ webid, webshape } } = this.state
    
    return (
      <Paper className={classes.paper}>
        <Map zoom={zoom} center={center} ref={this.map} className={classes.map}>
          <WMTSTileLayer
            url="https://tile.informatievlaanderen.be/ws/raadpleegdiensten/wmts"
            layer="grb_bsk"
            tilematrixSet="GoogleMapsVL"
            format="image/png"
            maxZoom="19"
            minZoom="1"
            title="GRB"
            attribution='<a target="_blank" href="https://overheid.vlaanderen.be/GRB-GRB-raadpleegdiensten">Grootschalig Referentie Bestand Vlaanderen, Informatie Vlaanderen</a>'
          />
          <GeoJSON
            key={webid}
            data={webshape}
            style={{
              color: "#1abc9c",
              weight: 2,
              opacity: 1,
              fillOpacity: 0.2,
              dashArray: "5,3"
            }}
          />
          <GeoJSON
            key={id}
            data={shape}
            style={{
              color: "#555",
              weight: 2,
              opacity: 1,
              fillOpacity: 0,
              dashArray: "5,3"
            }}
          />
          <FeatureGroup
            onClick={this._clickFeature}
            ref={reactFGref => {
              //this._onFeatureGroupReady(reactFGref);
            }}
          >
            <EditControl
              position="topright"
              onEdited={this._onEdited}
              onCreated={this._onCreated}
              onDeleted={this._onDeleted}
              onMounted={this._onMounted}
              onEditStart={this._onEditStart}
              onEditStop={this._onEditStop}
              onDeleteStart={this._onDeleteStart}
              onDeleteStop={this._onDeleteStop}
              draw={{
                rectangle: false
              }}
            />
          </FeatureGroup>
        </Map>
      </Paper>
    )
  }
  
  _editableFG = null;
  
  _onFeatureGroupReady = reactFGref => {
    // populate the leaflet FeatureGroup with the geoJson layers
    
    let leafletGeoJSON = new L.GeoJSON(getGeoJson());
    let leafletFG = reactFGref.leafletElement;
    
    leafletGeoJSON.eachLayer(layer => {
      leafletFG.addLayer(layer);
    });
    
    // store the ref for future access to content
    
    this._editableFG = reactFGref;
  };
  
  _onChange = () => {
    // this._editableFG contains the edited geometry, which can be manipulated through the leaflet API
    
    const { onChange } = this.props;
    
    if (!this._editableFG || !onChange) {
      return;
    }
    
    const geojsonData = this._editableFG.leafletElement.toGeoJSON();
    onChange(geojsonData);
  };
}

function getGeoJson() {
  return null;
}

Content.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Content);
