import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import ZoomInIcon from '@material-ui/icons/ZoomIn';

const styles = theme => ({
  categoryHeader: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    paddingTop: 4,
    paddingBottom: 4,
    color: 'rgba(255, 255, 255, 0.7)',
  },
  itemCategory: {
    backgroundColor: '#232f3e',
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: 16,
    paddingBottom: 16,
  },
  firebase: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.white,
  },
  itemPrimary: {
    color: 'inherit',
    fontSize: theme.typography.fontSize,
    '&$textDense': {
      fontSize: theme.typography.fontSize,
    },
  },
  textDense: {},
  divider: {
    marginTop: theme.spacing.unit * 2,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '100%',
    color: 'rgba(255, 255, 255, 0.7)',
  },
});

class Navigator extends Component {
  constructor (props)
  {
    super(props);
  }
  
  handleBufferChange = name => event => {
    this.setState({ [name]: event.target.value });
    this.props._handleBuffer(event.target.value);
  };
  
  render() {
    const { classes, ...other } = this.props;
    const pdata = this.props.pdata;
    const buffer = this.props.buffer;
    return (
      <Drawer variant="permanent" anchor="right" {...other}>
        <List disablePadding>
          <ListItem className={classNames(classes.firebase, classes.item, classes.itemCategory)}>
            Perimeter
          </ListItem>
          <ListItem className={classNames(classes.item, classes.itemCategory)}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.itemPrimary,
              }}
            >
              Perimeter berekenen
            </ListItemText>
          </ListItem>
          <ListItem className={classes.categoryHeader}>
            <ListItemText
              classes={{
                primary: classes.categoryHeaderPrimary,
              }}
            >
              1. Geef de buffer in: <TextField
              id="standard-number"
              value={buffer}
              onChange={this.handleBufferChange('buffer')}
              type="number"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              margin="none"
            />
            </ListItemText>
          </ListItem>
          <Divider/>
          <ListItem className={classes.categoryHeader}>
            <ListItemText
              classes={{
                primary: classes.categoryHeaderPrimary,
              }}
            >
              2. Selecteer uw shape
            </ListItemText>
          </ListItem>
          <Divider/>
          <ListItem className={classes.categoryHeader}>
            <ListItemText
              classes={{
                primary: classes.categoryHeaderPrimary,
              }}
            >
              3. Profit!
            </ListItemText>
          </ListItem>
          <Divider/>
        </List>
        <List className={classes.pdataroot}>
          {pdata.map(({ id, properties }) => (
            <React.Fragment key={id}>
              <ListItem>
                <Avatar><ZoomInIcon /></Avatar>
                <ListItemText className={classes.listText} primary={properties.CAPAKEY} secondary={"UIDN: " + properties.UIDN + " - OIDN: " + properties.OIDN} />
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </Drawer>
    );
  }
}

Navigator.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Navigator);
